@import "../../all-modules.global";

.button {
  @include button-type;
  @include button-size;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: none;
  text-decoration: none;
  transition: color .3s linear, background .3s linear, border .3s linear;
  cursor: pointer;
  min-width: 80px;
  font-family: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  font-weight: 400;
  margin: 0 0 8px 0;
  box-sizing: border-box;
  text-align: center;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--background-color);

  &_rounded {
    border-radius: 2rem;
  }

  &:disabled, &_disabled {
    cursor: not-allowed;
    opacity: $disable-opacity;
    pointer-events: none;
  }

  &__icon {
    width: 24px;
    height: 24px;
    background-color: var(--text-color);
  }

  &_type_empty {
    background: none;
    box-shadow: none;
    padding-left: 0;
    margin: 0 auto 0 0;
    text-decoration: underline;
    border: none;
    font-size: inherit;
    color: var(--color);
    font-weight: 400;
  }

  &_type_empty:focus {
    border: none;
    box-shadow: none;
  }

  &_loading {
    @include loading-button;
  }
}

