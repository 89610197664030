@import "../../all-modules.global";

.link {
  cursor: pointer;
  display: inline-block;
  color: var(--link);

  @include text-size;

  &:hover {
    color: var(--link-hover);
  }
}
