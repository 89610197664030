@import "../../all-modules.global";

.error-page {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  padding: 0 16px;

  &__container {
    width: 400px;
    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__heading {
    font-weight: 600;
  }

  &__button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    text-align: center;
    justify-content: center;
    width: 100%;

    button {
      margin: 0;
      flex-grow: 1;
    }
  }

  &__icon {
    z-index: 1;
    width: 160px;
  }

  @media (max-width: $mobile-width) {
    &__container {
      margin: 60px auto 0;
      width: 270px;
    }

    &__button {
      width: 100%;
    }

    &__icon {
      width: 100px;
    }
  }
}
