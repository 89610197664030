@import "../../all-modules.global";

.file-button {
  @include button-type;
  @include button-size;

  flex-direction: column;
  transition: color .3s linear, background .3s linear, border .3s linear;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 4px;
  text-align: center;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--background-color);
  display: inline-block;
  cursor: copy;
  line-height: inherit;
  width: inherit;

  &__input {
    display: none;
  }


  &:disabled, &_disabled {
    cursor: not-allowed;
    opacity: $disable-opacity;
    pointer-events: none;
  }
}