/*** animated-background ***/
.paragraph_size_xs {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}
.paragraph_size_s {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}
.paragraph_size_sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}
.paragraph_size_m {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
}
.paragraph_size_l {
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
}
.paragraph_size_xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
}
.paragraph_size_xxl {
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: 800;
}