@import "../../all-modules.global";

.button-link {
  text-transform: none;
  text-decoration: underline;
  font-family: inherit;
  font-weight: 400;
  margin: 0;
  text-align: left;
  background-color: transparent;
  border: none;

  cursor: pointer;
  display: inline-block;
  text-overflow: ellipsis;
  color: var(--link);

  @include text-size;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    color: #035fff;
  }
}
